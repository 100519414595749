import styled from 'styled-components'

export const StyledVoting = styled.div`
  .rewards {
    ul {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    li {
      display: block;
    }
  }
`
