import styled from 'styled-components'

export const StyledDoc = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  > div {
    display: grid;
    grid-template-columns: 100px 300px;
    gap: 10px;
    align-items: center;
  }
`
