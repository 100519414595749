import styled from 'styled-components'

export const StyledDonorPerks = styled.div`
  > div {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 50px;

    &:not(:first-child) {
      border-top: 1px solid #e2e8f0;
      margin-top: 2rem;
      padding-top: 2rem;
    }

    h3 {
      margin-bottom: 0;
    }

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 5px;
      column-gap: 20px;
      margin: 1rem 0 0;
      padding: 0;
    }

    li {
      display: flex;
    }
  }
`
