import styled from 'styled-components'
import McText from 'mctext-react'

export const StyledTooltip = styled.div`
  background: rgba(0, 0, 0, 0.9);
  border: 3px solid var(--color__mc-8);
  border-radius: 10px;
  display: inline-flex;
  flex-direction: column;
  font-family: 'Formal436 BT';
  color: #fff;
  padding: 20px;

  h4 {
    margin-top: 0;
    font-family: 'Formal436 BT';
    border-top: 2px dashed var(--color__mc-8);
    border-bottom: 2px dashed var(--color__mc-8);
    padding: 10px 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
  }
`

export const Txt = styled(McText)`
  font-family: 'Formal436 BT' !important;
`
