import React from 'react'
import PropTypes from 'prop-types'
import { StyledTooltip, Txt } from './Tooltip.styles'

const Tooltip = ({ children, type, tier, name, nameColor, attributes }) => {
  return (
    <StyledTooltip className={tier}>
      {type === 'txt' && <Txt>{children}</Txt>}
      {type === 'item' ? (
        <>
          <h4>
            <Txt>{nameColor ? `§${nameColor}${name}` : name}</Txt>
          </h4>
          <ul>
            {attributes.map((attribute, index) => (
              <li key={index}>{attribute}</li>
            ))}
          </ul>
        </>
      ) : (
        children
      )}
    </StyledTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  tier: PropTypes.string,
  name: PropTypes.string,
  nameColor: PropTypes.string,
  attributes: PropTypes.array,
}

export default Tooltip
